import { format } from 'date-fns/esm'

import { normalizeRmAccount } from '@/local/server-data/domain/helpers/userProfile'
import { getDefaultMapStyles } from '@/local/server-data/utils/map'
import { generateLists } from '@/local/server-data/utils/lists'

const defaultNotificationTemplateLimits = {
  OrderName: 20,
  CompanyName: 20,
  CompanyEmail: 20,
  CompanyPhone: 20,
  CompanySlogan: 20,
  PlannedDate: 6,
  PlannedTime: 17,
  ETA: 7,
  ETAthreshold: 6,
  DriverName: 20,
  OrderAddress: 20,
  OrderStatus: 17,
  TrackingLink: 22,
}

export const generateEmptyPublicData = (
  initialProfile: uui.domain.client.UserProfile = emptyUserProfile,
  mapStyles: uui.domain.ui.map.markers.MapStyles = getDefaultMapStyles(
    !!initialProfile.account.licensingLimits.gpsOnly,
  ),
  storedListOptions: uui.domain.StoredListOptions = {},
): uui.domain.PublicData => {
  const startOfToday = new Date().toISOString()
  const today = format(new Date(), 'yyyyMMdd')

  const account = normalizeRmAccount(initialProfile.account, initialProfile.user)

  return {
    profile: initialProfile,
    calendarRange: { minDate: today, maxDate: today },
    breadcrumbTimeRange: 'live',

    deviceIdsForEvents: [],

    userConfiguration: {
      authenticated: false,

      language: 'en',
      transitions: [],
      today,
      startOfToday,
      startFromDate: today,

      userType: 'rmViewer',
      rmAdmin: false,

      disableWebGl: !!initialProfile.user.disableWebGl,
      weekStartsOn: initialProfile.user.uiData.weekStartsOn ?? 'su',

      currency: account.preferences.currency,
      dateFormat: account.preferences.dateFormat,
      distanceFormat: account.preferences.distanceFormat,
      timeFormat: account.preferences.timeFormat,
      dateFormatLong: account.preferences.dateFormatLong!,
      dateFormatShort: account.preferences.dateFormatShort!,
      dateFormatShortWithExtendedMonth: account.preferences.dateFormatShortWithExtendedMonth!,
      dateParsingFormats: account.preferences.dateParsingFormats!,

      isLoginAs: initialProfile.isLoginAs,

      planType: 'none',
    },

    territories: {},
    territory: {
      center: {
        lat: 0,
        lng: 0,
      },

      id: '',
      accountId: '',
      name: '',
      country: '',
      deleted: false,
      workingDayStartSec: 0,
      timeZoneCode: '',
      licensingLimits: {
        allowVehicleOpenStart: true,
        maxSims: 0,
        type: '',
        vehiclesOps: 0,
        vehiclesSim: 0,
        horizonOpt: 0,
        horizonView: 0,
        orderRadiusMt: 0,
        ordersOps: 0,
        ordersSim: 0,
        trackedVehicles: 0,
        vehicleRadiusMt: 0,
        maxStatusReasonsCount: 0,
      },
      languageCode: '',
      autoArchiveAfterDays: 0,
      autoArchiveTimeSec: 0,
      vreRoutingProfile: '',
      vreRoutingTemplates: [],
      rescheduleBehavior: 'ON_REPORTED_ONLY',
      rescheduleIntervalSec: 0,
      rescheduleEdgeLocation: 'ROUTESTEP',
      oosBehavior: '',
      mobileAppViewPastDays: 0,
      mobileAppInteraction: { type: 'NONE' },
      mobileAppMoveSampleIntervalSec: 0,
      mobileAppIdleSampleIntervalSec: 0,
      startFromDate: '',
      notificationBehavior: 'NO_NOTIFICATIONS',
      stats: {
        simulationsNumber: 0,
        operationsVehicles: 0,
      },
      notificationConfig: {
        companyName: '',
        companyEmail: '',
        companyPhone: '',
        companyLogoToken: '',
        companySlogan: '',
        companyFromName: '',
        showContactPhone: false,
        showContactEmail: false,
        timeFormat: 'H12',
        dateFormat: 'MMMD',
        testURL: '',
        supportedLanguages: {},
        notificationLanguage: 'en',
        planDetails: {
          settings: {
            trialPlanAvailable: false,
            hasSubscription: false,
          } as uui.domain.server.CountryNotificationSettings,

          counters: {
            smsCount: 0,
            emailCount: 0,
            callCredits: 0,
            emailCountStartTs: 0,
          },
        },

        tomorrowSettings: {
          includeMessageUnsubscriptionInstructions: false,
          templateDefaultLimits: defaultNotificationTemplateLimits,
          templateCustomLimits: {},
          includeMessageTrackingLink: false,
          backupNotification: false,
          backupSubjectCustom: '',
          templatePreview: '',
          templateDefault: '',
          templateCustom: '',
          subjectDefault: '',
          notificationWay: 'EMAIL',
          subjectCustom: '',
          enabled: false,

          notificationCutoffMins: -1,
          alwaysNotifyAfterCutoff: false,

          windowOfCommitmentSnapToMins: -1,
          windowOfCommitmentToleranceMins: 30,
        },
        twoDaysSettings: {
          includeMessageUnsubscriptionInstructions: false,
          templateDefaultLimits: defaultNotificationTemplateLimits,
          templateCustomLimits: {},
          includeMessageTrackingLink: false,
          backupNotification: false,
          backupSubjectCustom: '',
          templatePreview: '',
          templateDefault: '',
          templateCustom: '',
          subjectDefault: '',
          notificationWay: 'EMAIL',
          subjectCustom: '',
          enabled: false,

          notificationCutoffMins: -1,
          alwaysNotifyAfterCutoff: false,

          windowOfCommitmentSnapToMins: -1,
          windowOfCommitmentToleranceMins: 30,
        },
        todaySettings: {
          includeMessageUnsubscriptionInstructions: false,
          templateDefaultLimits: defaultNotificationTemplateLimits,
          templateCustomLimits: {},
          includeMessageTrackingLink: false,
          backupNotification: false,
          backupSubjectCustom: '',
          templatePreview: '',
          templateDefault: '',
          templateCustom: '',
          subjectDefault: '',
          notificationWay: 'EMAIL',
          subjectCustom: '',
          enabled: false,

          notificationCutoffMins: -1,
          windowOfCommitmentSnapToMins: -1,
          windowOfCommitmentToleranceMins: 30,
        },
        completedSettings: {
          includeMessageUnsubscriptionInstructions: false,
          templateDefaultLimits: defaultNotificationTemplateLimits,
          templateCustomLimits: {},
          includeMessageTrackingLink: false,
          backupNotification: false,
          backupSubjectCustom: '',
          templatePreview: '',
          templateDefault: '',
          templateCustom: '',
          subjectDefault: '',
          notificationWay: 'EMAIL',
          subjectCustom: '',
          enabled: false,
        },
        notCompletedSettings: {
          includeMessageUnsubscriptionInstructions: false,
          templateDefaultLimits: defaultNotificationTemplateLimits,
          templateCustomLimits: {},
          includeMessageTrackingLink: false,
          backupNotification: false,
          backupSubjectCustom: '',
          templatePreview: '',
          templateDefault: '',
          templateCustom: '',
          subjectDefault: '',
          notificationWay: 'EMAIL',
          subjectCustom: '',
          enabled: false,
        },
        canceledSettings: {
          includeMessageUnsubscriptionInstructions: false,
          templateDefaultLimits: defaultNotificationTemplateLimits,
          templateCustomLimits: {},
          includeMessageTrackingLink: false,
          backupNotification: false,
          backupSubjectCustom: '',
          templatePreview: '',
          templateDefault: '',
          templateCustom: '',
          subjectDefault: '',
          notificationWay: 'EMAIL',
          subjectCustom: '',
          enabled: false,
        },
        almostThereSettings: {
          includeMessageUnsubscriptionInstructions: false,
          templateDefaultLimits: defaultNotificationTemplateLimits,
          templateCustomLimits: {},
          includeMessageTrackingLink: false,
          backupNotification: false,
          backupSubjectCustom: '',
          templatePreview: '',
          templateDefault: '',
          templateCustom: '',
          subjectDefault: '',
          notificationWay: 'EMAIL',
          subjectCustom: '',
          enabled: false,

          proximityThresholdMins: -1,
        },
        earlySettings: {
          includeMessageUnsubscriptionInstructions: false,
          templateDefaultLimits: defaultNotificationTemplateLimits,
          templateCustomLimits: {},
          includeMessageTrackingLink: false,
          backupNotification: false,
          backupSubjectCustom: '',
          templatePreview: '',
          templateDefault: '',
          templateCustom: '',
          subjectDefault: '',
          notificationWay: 'EMAIL',
          subjectCustom: '',
          enabled: false,

          toleranceMins: -1,
        },
        delayedSettings: {
          includeMessageUnsubscriptionInstructions: false,
          templateDefaultLimits: defaultNotificationTemplateLimits,
          templateCustomLimits: {},
          includeMessageTrackingLink: false,
          backupNotification: false,
          backupSubjectCustom: '',
          templatePreview: '',
          templateDefault: '',
          templateCustom: '',
          subjectDefault: '',
          notificationWay: 'EMAIL',
          subjectCustom: '',
          enabled: false,

          toleranceMins: -1,
        },
      },
      statusReasons: [],
      voiceConfig: {
        status: 'OFF',
      },
      voiceLanguageOptions: {
        maleAvailable: false,
        femaleAvailable: false,
      },
      mobileAppsTrackingType: 'ONLY_ORDERSTEPS',
      enableDynamicLoads: false,
      enableDynamicCustomFields: false,
      borderRestrictionCode: '',
      borderRestrictionFullName: 'Not Enabled',
    },

    domain: {
      domainRevision: 1,

      gps: {
        wwgps: {
          tags: {},
          places: {},
          geofences: {},
          eventInfos: {},
          eventAlerts: {},
          livePositions: {},
          devicesAggregateInfo: {},
        },

        telematics: {
          sources: {},
          deviceInfos: {},
          livePositions: {},
          tenantSources: {},
          deviceIdsByTenantSource: {},
          deviceTransientStatuses: {},
        },
      },

      rm: {
        plan: {
          id: '',
          version: -1,
          // revision: -1,
          currentRevision: -1,
          revisionHistory: [],
          snapshotsByCalendarRange: {},
          activeOrdersCountByDate: {},
          opsOrdersCountByDate: {},
        },

        apiKey: undefined,

        traffic: {
          timeWindows: [],
          profiles: {},
        },
        regions: {},
        routing: {
          profiles: {},
        },
        driverAssignments: {},
        approvedPlans: {},
        drivers: {},
        depots: {},

        orders: {},

        routes: {},

        scheduler: {
          events: {},
          resources: {},
          routesInfo: {},
          schedulerRoutes: {},
          routesInfoIdsInRange: [],
          eventIdsByResourceId: {},
          orderStepIdSchedulerMapping: {},
        },

        unassignedOrderStepsId: [],
        orderStepsIdInRange: {},

        simulations: {},

        users: {},

        routePlanTrackingData: {
          routesTrackingData: {},
          orderStepTrackingDataRegistry: {},
        },
      },
      vehicles: {},
      vehiclesByVehicleId: {},
      vehiclesByDeviceId: {},

      mapMarkers: {
        order: {},

        depot: {},
        region: {},
        vehicle: {},
        trafficProfile: {},
        roadSegment: {},
        routePolyline: {},

        place: {},
        device: {},
        geofence: {},
        breadcrumb: {},
        deviceEvent: {},
      },

      mapStyles,

      lists: generateLists(storedListOptions),
    },

    applicationClock: new Date().getTime(),

    qa: {
      gps: {
        telematics: {
          tenantSourceState: {},
        },
      },
    },
  }
}

const emptyUserProfile: uui.domain.client.UserProfile = {
  fullName: '',
  supportedCountries: {},
  timeZones: [],
  colors: [],
  buildCommit: '',
  isViewOnly: false,
  isLoginAs: false,
  gmapsClientId: '',
  authtoken: '',
  websocketUrl: '',
  account: {
    id: '',
    credentials: {
      username: '',
      voEnabled: true,
      voHasPassword: true,
      trackingProvider: 'none',
    },
    preferences: {
      currency: '',
      allowEmptyCustomFields: false,
      dateFormat: 'YMD',
      distanceFormat: 'METRIC',
      timeFormat: 'hh:mm aa',
      lastOpenedPlan: {
        id: '',
        territoryId: '',
      },
      uiColumnPosition: {},
      uuiImportMapping: {},
      dateFormatLong: 'MM/dd/yyyy',
      dateFormatShort: 'MMM dd',
      dateParsingFormats: [
        'MM/dd/yyyy',
        'MMM D',
        'MMM D yyyy',
        'yyyyMMdd',
        'yyyy/M/D',
        'M/D/yyyy',
        'M/D/YY',
      ],
      dateFormatShortWithExtendedMonth: 'MMMM dd',
    },
    details: {
      name: '',
      surname: '',
      email: '',
      country: '',
      timeZoneCode: '',
      companyName: '',
      resellerEmail: '',
    },
    licensingLimits: {
      allowApiVehicles: false,
      allowBarcodes: false,
      allowTracking: false,
      maxBarcodes: 0,
      maxSegmentExceptions: 0,
      maxTags: 20,
      maxCustomFieldsPerOrder: 0,
      trackedVehicles: 0,
      allowApi: false,
      allowGps: false,
      allowProposals: false,
      deleted: false,
      privileges: ['FREE'],
      suspended: false,
      vehicles: 50,
      gpsOnly: false,
    },
    accountData: {
      companies: {},
    },
  },
  user: {
    id: '',
    accountId: '',
    enabled: true,
    territoryFilter: [],
    name: '',
    surname: '',
    email: '',
    settings: {
      currency: '$',
      dateFormat: 'YMD',
      timeFormat: 'hh:mm aa',
      distanceFormat: 'METRIC',
      language: 'en',
    },
    type: 'viewer',
    username: '',
    uiData: {
      version: 1,
      exportOrders: {
        format: 'CSV',
        includeBarcodes: true,
      },
      exportRoutes: {
        format: 'CSV',
        includePod: true,
        includeBarcodes: true,
      },
      weekStartsOn: 'su',
    },
    disableWebGl: false,

    companies: [],
    isCompanyFiltered: false,
  },
  privilegesList: ['FREE'],
  routingProfiles: {},
  cookieUrl: '',
  clientVersion: '',
  clientReloadPriority: 'silent',
  serverVersion: '',
  serverInstance: '',

  hereAuthToken: {
    token: '',
    expiryTs: -1,
  },
}
